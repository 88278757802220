<template>
  <div>
    <div style="font-size: 18px;font-weight: bold;text-align: left;margin-left: 5vw;margin-bottom: 10vh;">
      首页数据修改
    </div>
    <div style="margin-left: -10vw;">
      <p>
        <el-link type="primary" :underline="false" style="margin-left: 100px;font-size:16px">当前显示数量</el-link>
        <el-link type="primary" :underline="false" style="margin-left: 50px;font-size:16px">修改后数量</el-link>
      </p>
      <p>测试人数数量：
        <el-input-number v-model="data.TestTotal" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.TestTotal" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p>持续恶化数量：
        <el-input-number v-model="data.Worsen" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.Worsen" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p>新增近视数量：
        <el-input-number v-model="data.Myopia" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.Myopia" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p>新增恢复数量：
        <el-input-number v-model="data.Recovery" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.Recovery" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p>持续好转数量：
        <el-input-number v-model="data.Improve" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.Improve" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p>健康比例数量：
        <el-input-number v-model="data.HealthRatio" :controls="false" style="width: 120px;" disabled></el-input-number> +
        <el-input-number v-model="form.HealthRatio" :controls="false" placeholder="修改后数量" style="width: 120px;">
        </el-input-number>
      </p>
      <p style="color: red;">上次更新时间：{{data.InsertTime}}</p>
      <p>
        <el-button type="primary" @click="onSubmit()">提交</el-button>
      </p>
    </div>
  </div>
</template>

<script>
  import {
    setIndex,
    getIndex
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        form: {}, //修改后数据
        data: {} //首页现在数据
      }
    },
    created() {
      this.getIndex() //获取首页数据
    },
    methods: {
      onSubmit() { //提交按钮
        let data = this.form;
        setIndex(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '数据修改成功',
              type: 'success'
            })
            this.form = {} // 清空
            this.getIndex()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      getIndex() { //获取首页数据
        getIndex().then(res => {
          this.data = res.data
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>

<style scoped>
</style>
